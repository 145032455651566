import React from 'react';

import ContentLayout from 'components/ContentLayout'

import ResultPage from 'containers/ProgressPage/ResultPage'

export default () => (
  <ContentLayout title="調解案件進度查詢">
    <ResultPage />
  </ContentLayout>
)
